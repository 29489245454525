<template>
    <div class="w-full">
        <div class="px-4 lg:py-6 lg:px-6 bg-siteBg-100">
            <div class="flex flex-row items-center justify-between lg:px-6">
                <div class="w-1/2">
                    <img src="/images/ssg-logo.png" class="h-20" />
                </div>
                <div class="w-1/2">
                    <div class="flex flex-row items-end justify-end">
                        <a href="tel:+60126984843" class="text-xl font-normal lg:text-3xl font-roboto text-siteBg-default">012-698 4843</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-16 bg-siteBg-400">
            <div class="container mx-auto">
                <div class="w-full mx-auto xl:w-11/12">
                    <h2 class="mb-12 text-4xl leading-none text-center lg:text-5xl 2xl:text-6xl font-playfair text-siteBg-default">Confirmation Message</h2>
                    <p class="center">Thank you for your kind interest in Sincere Service Group.</p>
                    <p class="mb-6 center">We will attend to your request as soon as possible.</p>
                    <div class="flex mt-12">
                        <a href="/" class="px-8 py-2 mx-auto text-lg text-center text-white rounded-full lg:px-10 lg:float-left bg-siteBg-default hover:underline">Back To HomePage</a>
                    </div>
                </div>
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Thank',
	components: {
		SiteFooter,
	},
}
</script>