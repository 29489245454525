<template>
    <div class="w-full">
		<WaBtn />
		<section class="w-full py-12 header lg:py-32">
			<div class="container">
				<div class="flex flex-wrap w-full mx-auto xl:w-11/12">
					<div class="w-full lg:w-full">
						<div class=""><img src="/images/ssg-logo.png" class="h-16 mb-12 lg:h-20 lg:mb-16" /></div>
						<h1 class="mb-4 text-2xl font-semibold leading-none tracking-wider text-black uppercase lg:text-4xl text-shadow-md tagline">Print On Demand</h1>
						<h2 class="text-4xl font-semibold leading-none tracking-wider text-black lg:text-6xl tagline">I Want To Print It Fast</h2>
						<div class="w-32 h-2 mt-8 bg-siteBg-300"></div>
						<div class="flex flex-wrap mt-12">
							<a href="#enquire" class="px-4 py-2 mb-1 mr-2 text-lg font-medium text-white bg-siteBg-default lg:py-4 lg:px-4 rounded-xl md:mb-0">Enquire Now</a>
							<a href="tel:+60126255113" class="px-4 py-2 mb-1 mr-2 text-lg font-medium text-white md:mb-0 bg-siteBg-default lg:py-4 lg:px-4 rounded-xl">Call us @ 012-625 5113(Mr Tan)</a>
							<a href="tel:+60176344404" class="px-4 py-2 text-lg font-medium text-white bg-siteBg-default lg:py-4 lg:px-4 rounded-xl">Call us @ 017-634 4404 (Dani)</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-12">
			<div class="container">
				<div class="w-full mx-auto xl:w-10/12">
					<h2 class="mb-8 text-2xl font-medium tracking-wider text-center uppercase lg:text-4xl">About us</h2>
					<p class="text-left lg:text-center">Sincere Service Centre Sdn Bhd (Sincere) has been in the Digital Printing industry since 1983, started off as a small photocopying and servicing outfit. Sincere assumed the private limited status in 1993 with the aim of sharpening corporate image and professionalism. Sincere has grown steadily that has been solidly proven with the establishment of excellent track record as a professional digital printing service provider in the industry.</p>
				</div>
				<div class="w-full mx-auto my-10 xl:w-10/12">
					<div class="grid grid-cols-3 gap-10 md:grid-cols-4 lg:grid-cols-7">
						<img src="/images/client-logo-01.jpg" class="w-full" />
						<img src="/images/client-logo-02.jpg" class="w-full" />
						<img src="/images/client-logo-03.jpg" class="w-full" />
						<img src="/images/client-logo-04.jpg" class="w-full" />
						<img src="/images/client-logo-05.jpg" class="w-full" />
						<img src="/images/client-logo-06.jpg" class="w-full" />
						<img src="/images/client-logo-07.jpg" class="w-full" />
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-12 bg-siteBg-100">
			<div class="container">
				<div class="w-full mx-auto xl:w-11/12">
					<h2 class="mb-8 text-2xl font-medium tracking-wider text-center uppercase lg:text-4xl">Products/Services</h2>
				</div>
				<div class="w-full mx-auto my-10 xl:w-11/12">
					<div class="grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-4">
						<div class="item-center">
							<img src="/images/service-img-01.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Graphic Design</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-02.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Short Run Digital Printing</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-04.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Book Publication</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-05.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Book Archiving & Reprint of Out - Of - Print</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-06.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Variable Data Printing</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-07.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Full Range of Binding</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-08.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Comprehensive & Complete Conference Documentation & Planning</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-09.jpg" class="w-full mb-4" />
							<h4 class="w-full text-lg font-medium text-center">Regional Hubbing for Foreign Clientele in Printing and Distribution</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-10.jpg" class="w-full mb-4" />
							<h4 class="w-full text-lg font-medium text-center">Sticker printing</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-11.jpg" class="w-full mb-4" />
							<h4 class="w-full text-lg font-medium text-center">Flyer Printing</h4>
						</div>
						<div class="item-center">
							<img src="/images/service-img-12.jpg" class="w-full mb-4" />
							<h4 class="w-full text-lg font-medium text-center">Name Card Printing</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-12 bg-siteBg-200"></section>
		<section class="w-full py-12">
			<div class="container">
				<div class="w-full mx-auto xl:w-11/12">
					<h2 class="mb-8 text-2xl font-medium tracking-wider text-center uppercase lg:text-4xl">Certificate/Awards</h2>
				</div>
				<div class="w-full mx-auto my-10 xl:w-11/12">
					<div class="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
						<div class="item-center">
							<img src="/images/awards-img-01.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">The Malaysia Book of Records (1999)</h4>
						</div>
						<div class="item-center">
							<img src="/images/awards-img-02.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">INNOVATIVE APPLICATIONS IMAGING AWARD (IAIA) by Fuji Xerox Malaysia (2005)</h4>
						</div>
						<div class="item-center">
							<img src="/images/awards-img-03.jpg" class="w-full mb-4" />
							<h4 class="w-full text-base font-medium text-center">Qualified as a member of Fuji Xerox Graphic Arts Premier Partners since 2004</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-12 bg-siteBg-100"></section>
		<section class="w-full py-12 bg-siteBg-200">
			<div class="container">
				<div id="enquiry" class="w-full mx-auto xl:w-10/12"><a name="enquire"></a>
					<h3 class="mb-8 text-2xl font-medium tracking-wider text-center text-white uppercase lg:text-3xl">Fill in the form and we'll be in touch with you soon</h3>
					<iframe allowTransparency="true" style="min-height:530px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5661211.html"></iframe>
				</div>
			</div>
		</section>
        <SiteFooter />
    </div>
</template>

<script>
import SiteFooter from '@/components/SiteFooter.vue'
import WaBtn from "@/components/WaBtn.vue";


export default {
	name: 'Home',
	components: {
		SiteFooter,
		WaBtn
	},
}
</script>

<style>
    .header{
        background-image: url('/public/images/main-banner-bg.jpg');
        @apply bg-center bg-no-repeat bg-cover;
    }
    .tagline{
        text-shadow: 5px 2px 10px #fff;
    }
	@media (min-width: 768px){
		#enquiry iframe{
			min-height: 400px !important;
		}
	}
</style>