import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
import Thank from '../views/thank-you.vue'
// import VueGtm from 'vue-gtm';

Vue.use(VueRouter)
// Vue.use(VueGtm, {
//   id: 'GTM-KX6F656',
// });

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/thank-you/',
    name: 'Thank',
    component: Thank
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router